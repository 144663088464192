import dayjs from "dayjs";
import numeral from "numeral";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import pathFunc from "path";
import ExplanationModal from "@/components/ExplanationModal.vue";
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
import generateUniqueId from "generate-unique-id";

import dayjsNb from "dayjs/locale/nb";
dayjs.locale(dayjsNb);

dayjs.updateLocale("nb", {
  relativeTime: {
    future: "om %s",
    past: "%s siden",
    s: "få sekunder",
    m: "ett minutt",
    mm: "%d minutter",
    h: "en time",
    hh: "%d timer",
    d: "en dag",
    dd: "%d dager",
    M: "en mnd.",
    MM: "%d mnd.",
    y: "ett år",
    yy: "%d år"
  }
});

/*
  activeOtion: the v-model of the field
  explanationType: the type of explanation to show. also set as ref on the field
*/
const showExplanationModal = function(activeOption, explanationType) {
  const propertyNames = activeOption.split(".");
  let optionValue = this;

  for (const propName of propertyNames) {
    if (optionValue && optionValue.hasOwnProperty(propName)) {
      optionValue = optionValue[propName];
    } else {
      optionValue = null;
      break;
    }
  }

  this.$buefy.modal.open({
    parent: this,
    component: ExplanationModal,
    hasModalCard: true,
    trapFocus: true,
    props: {
      activeOption: optionValue,
      type: explanationType
    },
    width: 960,
    events: {
      "select-option": value => {
        const propertyNames = activeOption.split(".");
        let optionObject = this;
        let propertyName;

        for (let i = 0; i < propertyNames.length - 1; i++) {
          propertyName = propertyNames[i];
          if (optionObject && optionObject.hasOwnProperty(propertyName)) {
            optionObject = optionObject[propertyName];
          } else {
            optionObject = null;
            break;
          }
        }

        if (optionObject !== null) {
          propertyName = propertyNames[propertyNames.length - 1];
          this.$set(optionObject, propertyName, value);
        }

        if (this.$refs[explanationType]) {
          let el = this.$refs[explanationType].$el;

          el.classList.add("changed");
          setTimeout(() => {
            el.classList.remove("changed");
          }, 1000);
        }

        // focus the field after setting the value for tab navigation
        this.$refs[explanationType].focus();

        this.$forceUpdate();
      }
    }
  });
};

const mediaPath = function(path) {
  return `https://cdn.stadssalg.no/?path=${pathFunc.dirname(
    path
  )}&file=${encodeURIComponent(pathFunc.basename(path))}`;
};

const mediaPathResized = function(path, options) {
  if (path != null) {
    let optn = new URLSearchParams(options).toString();

    return `https://cdn.stadssalg.no/?${optn}&path=${pathFunc.dirname(
      path
    )}&file=${encodeURIComponent(pathFunc.basename(path))}`;
  } else {
    return "";
  }
};

const validIdLookup = function(q) {
  if (!isNaN(q) && q.length == 5) {
    return true;
  } else {
    return false;
  }
};

const checkIfAdminFee = function(records, percentageRate) {
  return !records.some(r => r.productId === 210) && percentageRate;
};

const getRelativeTime = function(dateTime) {
  return dayjs(dateTime).fromNow(true);
};

const disableAutofill = function() {
  return generateUniqueId(10);
};

const getYear = function(datetime, fullYear = false) {
  // if firestore date, convert it
  if (datetime?._seconds) {
    datetime = new Date(datetime._seconds * 1000);
  }
  if (datetime) {
    return dayjs(datetime).format("YY" + (fullYear ? "YY" : ""));
  } else {
    return null;
  }
};

const getFormattedDateTime = function(dateTime, format) {
  if (!dateTime) {
    return null;
  }

  if (!format) {
    format = "DD.MM.YYYY HH:mm:ss";
  }
  return dayjs(dateTime).format(format);
};

const getFormattedNumeral = function(number, format) {
  if (format) {
    return numeral(number).format(format);
  } else {
    return numeral(number).format("0,0") + ".-";
  }
};

const isDateField = function(value) {
  if (value && !isNaN(parseInt(value))) {
    if (parseInt(value) >= 1000000000000) return true;
    else return false;
  } else {
    return false;
  }
};

const isDate = function(value) {
  const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)$/;
  return regex.test(value);
};

const getModuleStatusTranslated = function(moduleName, value) {
  return this.$i18n.t(`items.${moduleName}Module.${value}`);
};

const isNavItem = function(agentId) {
  if (!agentId) {
    return false;
  }

  const navAgentIds = [
    215, // NAV Bilsenter Nordland
    120, // NAV Bilsenter Midt-Norge
    327, // NAV Bilsenter Øst
    328, // NAV Bilsenter Sør-Vest
    329 // NAV Bilsenter Vest
  ];

  return navAgentIds.includes(agentId);
};

const isSantanderItem = function(agentId) {
  if (agentId && agentId == 259) {
    return true;
  } else {
    return false;
  }
};

const getModuleIcon = function(moduleName) {
  switch (moduleName) {
    case "sales":
      return "handshake-outline";
    case "preparation":
      return "marker-check";
    case "info":
      return "file-outline";
    case "valuation":
      return "cash";
    case "economy":
      return "currency-usd";
    case "museum":
      return "bank";
    default:
      return "card-bulleted";
  }
};

const progressColor = function(expiration, module, createdAt, progress) {
  if (progress === 100) {
    return "is-success is-light";
  }

  let date = new Date();
  let compareDate = new Date(createdAt);
  let expirationDate = null;
  let danger = false;

  if (expiration) {
    expirationDate = new Date(expiration);

    if (date > expirationDate) {
      return "is-danger is-light";
    } else {
      return "is-success is-light";
    }
  }

  // we decide color based on percentage
  if (!expirationDate) {
    if (progress >= 0 && progress <= 25) {
      return "is-danger";
    } else if (progress > 10 && progress <= 35) {
      return "is-danger is-light";
    } else if (progress > 35 && progress <= 80) {
      return "is-warning is-light";
    } else if (progress > 80 && progress <= 100) {
      return "is-success is-light";
    }
  }

  /*
  // walk through every module
  switch (module) {
    case "preparation":
      if (date > getDateXDaysTo(5, compareDate)) {
        danger = true;
      }
      break;
    case "info":
      if (date > getDateXDaysTo(10, compareDate)) {
        danger = true;
      }
      break;
    case "valuation":
      // compareDate bør være 10 dager fra takst bestilt (valuationOrdered)
      if (date > getDateXDaysTo(24, compareDate)) {
        danger = true;
      }
      break;
    case "sale":
      if (date > getDateXDaysTo(38, compareDate)) {
        danger = true;
      }
      break;

    case "economy":
      if (date > getDateXDaysTo(48, compareDate)) {
        danger = true;
      }
      break;

    case "museum":
      if (date > getDateXDaysTo(365, compareDate)) {
        danger = true;
      }
      break;

    default:
      if (date > getDateXDaysTo(10, compareDate)) {
        danger = true;
      }
      break;
  } */

  if (danger) {
    return "is-danger is-light";
  } else {
    return "is-success is-light";
  }
};

const fetchOrg = async function() {
  if (this.form.organizationNumber) {
    this.$axios
      .get(
        `https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=${this.form.organizationNumber}`
      )
      .then(response => {
        if (response.data._embedded && response.data._embedded.enheter) {
          let data = response.data._embedded.enheter[0];

          if (data.organisasjonsform) {
            this.form.organizationIndustry = data.organisasjonsform.beskrivelse;
          }

          if (data.naeringskode1) {
            this.form.organizationType = data.naeringskode1.beskrivelse;
          }

          this.form.address1 = data.forretningsadresse.adresse[0];

          if (data.forretningsadresse.adresse[1]) {
            this.form.address2 = data.forretningsadresse.adresse[1];
          }

          /*
          if (!street && data.postadresse.adresse[0]) {
            street = data.postadresse.adresse[0];
          }

          if (street) {
            var streetSplit = street.split(/(\d+)/g);
            this.form.address1 =
              streetSplit[0].trim() + " " + streetSplit[1].trim();
          } else {
            this.form.address1 = "";
          }*/

          this.form.name = data.navn;
          this.form.countryCode = data.forretningsadresse.landkode;

          this.form.zip = data.forretningsadresse.postnummer;
          this.form.city = data.forretningsadresse.poststed;

          this.$buefy.toast.open({
            message: "Hentet bedriftsopplysninger",
            type: "is-success"
          });
        } else {
          this.$buefy.toast.open({
            message: "Kunne ikke hente bedriftsinfo",
            type: "is-danger"
          });
        }
      })
      .catch(error => {
        this.$buefy.toast.open({
          message: error.message,
          type: "is-danger"
        });
      });
  }
};

function getDateXDaysAgo(days, baseDate = new Date()) {
  let date = new Date(baseDate);
  date.setDate(date.getDate() - days);

  return date;
}

function getDateXDaysTo(days, baseDate = new Date()) {
  let date = new Date(baseDate);
  date.setDate(date.getDate() + days);

  return date;
}

const checkRequiredFields = function(data, fields) {
  const missingFields = [];

  for (const field of fields) {
    const fieldParts = field.split(".");
    let value = data;

    for (const part of fieldParts) {
      if (value && value.hasOwnProperty(part)) {
        value = value[part];
      } else {
        // If any part of the field is missing, add it to the missingFields array
        missingFields.push(field);
        break; // Exit the inner loop
      }
    }

    // If the final value is empty, add the field to the missingFields array
    if (!value) {
      missingFields.push(field);
    }
  }

  // Return an object with the result and the list of missing fields
  return {
    areAllFieldsFilled: missingFields.length === 0,
    missingFields: missingFields
  };
};

export default {
  methods: {
    fetchOrg,
    getModuleStatusTranslated,
    mediaPath,
    mediaPathResized,
    validIdLookup,
    getFormattedDateTime,
    getFormattedNumeral,
    getYear,
    isDateField,
    isDate,
    getRelativeTime,
    disableAutofill,
    getModuleIcon,
    getDateXDaysAgo,
    getDateXDaysTo,
    checkRequiredFields,
    progressColor,
    showExplanationModal,
    isNavItem,
    isSantanderItem,
    checkIfAdminFee
  }
};
