<template>
  <div class="mt-5">
    <div class="">
      <b-table
        :mobile-cards="false"
        class="is-striped"
        ref="table"
        :data="items"
        :loading="isLoading"
        paginated
        :per-page="50"
        aria-next-label="Neste side"
        aria-previous-label="Forrige side"
        aria-page-label="side"
        aria-current-label="Gjeldende side"
      >
        <b-table-column field="id" label="ID" v-slot="props" sortable>
          <router-link
            v-if="props.row.id"
            :to="{ name: 'item.edit', params: { id: props.row.id } }"
          >
            {{ props.row.id }}
          </router-link>
        </b-table-column>

        <b-table-column field="title" label="Tittel" v-slot="props">
          {{ props.row.item.title }}
        </b-table-column>

        <b-table-column field="agent" label="Oppdragsgiver" v-slot="props">
          {{
            props?.row?.item?.agent?.company?.name &&
            props.row.item.agent.company.name.length > 0
              ? props.row.item.agent.company.name
              : "Ingen oppdragsgiver"
          }}
        </b-table-column>

        <b-table-column
          field="contractSigningStatus"
          sortable
          label="Signeringsstatus"
          v-slot="props"
        >
          {{
            $t(`items.saleModule.${props.row.item.sale.contractSigningStatus}`)
          }}
        </b-table-column>

        <b-table-column
          field="paymentReceivedDate"
          sortable
          label="Betaling mottatt"
          v-slot="props"
        >
          {{
            helpers.methods.getFormattedDateTime(
              props.row.paymentReceivedDate,
              "DD.MM.YYYY"
            )
          }}
        </b-table-column>

        <b-table-column
          field="checkedOut"
          sortable
          label="Utmeldt"
          v-slot="props"
        >
          {{ helpers.methods.getFormattedDateTime(props.row.checkedOut) }}
        </b-table-column>
        <b-table-column
          field="transferOfOwnershipDocumentationUploaded"
          sortable
          label="Eierskifte lastet opp"
          v-slot="props"
        >
          {{
            helpers.methods.getFormattedDateTime(
              props.row.transferOfOwnershipDocumentationUploaded
            )
          }}</b-table-column
        >
        <b-table-column
          field="settlementDate"
          sortable
          label="Oppgjør tatt"
          v-slot="props"
        >
          {{ helpers.methods.getFormattedDateTime(props.row.settlementDate) }}
        </b-table-column>
        <b-table-column
          field="bankruptcyTitle"
          sortable
          label="Konkurs"
          v-slot="props"
        >
          {{
            props?.row?.item?.bankruptcy?.name &&
            props.row.item.bankruptcy.name.length > 0
              ? props.row.item.bankruptcy.name
              : ""
          }}
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script setup>
import helpers from "../../helpers";
import { ref, getCurrentInstance } from "vue";

const items = ref([]);
const isLoading = ref(false);

const app = getCurrentInstance().proxy;

const loadAsyncData = () => {
  isLoading.value = true;
  app.$axios
    .get(`${window.apiURL}/admin/dashboard/settlements`)
    .then(response => {
      isLoading.value = false;
      items.value = response.data;
    })
    .catch(e => {
      isLoading.value = false;
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: "is-danger",
        queue: false
      });
    });
};

loadAsyncData();
</script>

<style lang="scss" scoped></style>
